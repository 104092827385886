import React, { useState, useEffect } from 'react'
import { ReactComponent as ArrowUpIcon } from 'assets/images/short_up_arrow.svg'
import { ReactComponent as ArrowDownIcon } from 'assets/images/short_down_arrow.svg'

interface CustomTimeInputProps {
  value: string
  onChange: (newValue: string) => void
}

const CustomTimeInput: React.FC<CustomTimeInputProps> = ({ value, onChange }) => {
  const [hours, setHours] = useState<number>(12)
  const [minutes, setMinutes] = useState<number>(0)
  const [ampm, setAmpm] = useState<'AM' | 'PM'>('AM')

  useEffect(() => {
    const [time, period] = value.split(' ')
    const [h, m] = time.split(':')
    setHours(parseInt(h, 10))
    setMinutes(parseInt(m, 10))
    setAmpm(period as 'AM' | 'PM')
  }, [value])

  const handleChange = (newHours: number, newMinutes: number, newAmpm: 'AM' | 'PM') => {
    const formattedHours = newHours.toString().padStart(2, '0')
    const formattedMinutes = newMinutes.toString().padStart(2, '0')
    onChange(`${formattedHours}:${formattedMinutes} ${newAmpm}`)
  }

  const incrementHours = () => {
    const newHours = hours === 12 ? 1 : hours + 1
    setHours(newHours)
    handleChange(newHours, minutes, ampm)
  }

  const decrementHours = () => {
    const newHours = hours === 1 ? 12 : hours - 1
    setHours(newHours)
    handleChange(newHours, minutes, ampm)
  }

  const incrementMinutes = () => {
    const newMinutes = (minutes + 1) % 60
    setMinutes(newMinutes)
    handleChange(hours, newMinutes, ampm)
  }

  const decrementMinutes = () => {
    const newMinutes = (minutes - 1 + 60) % 60
    setMinutes(newMinutes)
    handleChange(hours, newMinutes, ampm)
  }

  const handleAmPmChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newAmpm = e.target.value as 'AM' | 'PM'
    setAmpm(newAmpm)
    handleChange(hours, minutes, newAmpm)
  }

  return (
    <div className='input-component-container'>
      <div className='input-box'>
        <span onClick={incrementHours} className='input-box__arrow-icon'>
          <ArrowUpIcon />
        </span>
        <input
          type='number'
          value={hours}
          onChange={(e) => {
            const newHours = Math.max(1, Math.min(12, parseInt(e.target.value, 10) || 1))
            setHours(newHours)
            handleChange(newHours, minutes, ampm)
          }}
          className='w-10 text-center'
        />
        <span onClick={decrementHours} className='input-box__arrow-icon'>
          <ArrowDownIcon />
        </span>
      </div>
      <div className='input-box'>
        <span onClick={incrementMinutes} className='input-box__arrow-icon'>
          <ArrowUpIcon />
        </span>
        <input
          type='number'
          value={minutes.toString().padStart(2, '0')}
          onChange={(e) => {
            const newMinutes = Math.max(0, Math.min(59, parseInt(e.target.value, 10) || 0))
            setMinutes(newMinutes)
            handleChange(hours, newMinutes, ampm)
          }}
          className='w-10 text-center'
        />
        <span onClick={decrementMinutes} className='input-box__arrow-icon'>
          <ArrowDownIcon />
        </span>
      </div>
      <select value={ampm} onChange={handleAmPmChange} className='am-pm-selection'>
        <option value='AM'>AM</option>
        <option value='PM'>PM</option>
      </select>
    </div>
  )
}

export default CustomTimeInput
