import { Col, Drawer, Dropdown, MenuProps, Row, Spin } from 'antd'
import { observer } from 'mobx-react-lite'

import { store } from 'store'

import { useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

import MentionViewCard from 'components/Asset/BrandsDashboard/components/MentionViewCard/MentionViewCard'
import { AssetListObjType, EntityTypes } from 'store/asset/types'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
// import profileImage from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as ExecutiveSummaryIcon } from 'assets/images/icons/assets/executive-summary.svg'
import { ReactComponent as ExpandIcon } from 'assets/images/icons/expand.svg'
import { DateFilter } from 'components/common/DateFilter/DateFilter'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { FlagListObjType } from 'store/flags/types'
import DailyExecutiveSummary from 'components/Asset/BrandsDashboard/components/DailyExecutiveSummary/DailyExecutiveSummary'
import SnippetsDiscovery from 'components/Asset/BrandsDashboard/components/SnippetsDiscovery/SnippetsDiscovery'
import Filters from 'components/Asset/BrandsDashboard/components/Filters/Filters'
import SuggestedIssues from 'components/Asset/BrandsDashboard/components/SuggestedIssues/SuggestedIssues'
import FollowedIssues from 'components/Asset/BrandsDashboard/components/FollowedIssues/FollowedIssues'
import InfluencerTable from 'components/Asset/BrandsDashboard/components/InfluencerTable/InfluencerTable'
import PortfolioShareChart from 'components/Asset/BrandsDashboard/components/PortfolioShareChart/PortfolioShareChart'
import BrandSentiment from 'components/Asset/BrandsDashboard/components/BrandSentiment/BrandSentiment'
import { DownloadOption } from 'components/Asset/BrandsDashboard/components/DownloadOption'
import PlatformDistributionCard from 'components/Asset/BrandsDashboard/components/PlatformDistributionCard/PlatformDistributionCard'
import { MonitorGraph } from 'components/Asset/BrandsDashboard/components/MonitorGraph/MonitorGraph'
import SearchList from 'components/Vectors/Dashboard/SearchList/SearchList'
import { DetailsObjType, NarrativeListDataType } from 'types/types'
import BrandDiscussions from 'components/Vectors/Dashboard/BrandDiscussions/BrandDiscussions'
import './VectorsDashboard.scss'
import classNames from 'classnames'
import PowerInsightTabsListVertical from 'components/Asset/BrandsDashboard/components/PowerInsightTabsListVertical/PowerInsightTabsListVertical'
import { SnippetFilter } from 'components/Monitor/SnippetFilter/SnippetFilter'
import { FilterChips } from 'components/Monitor/FilterChips/FilterChips'
import { SnippetList } from 'components/Monitor/SnippetList/SnippetList'
import { ReactComponent as PowerInsightsIcon } from 'assets/images/icons/powerinsight_icon.svg'
import { ReactComponent as LeftArrowIcon } from 'assets/images/icons/power-insights/arrow-left.svg'
import { ReactComponent as ThreeDotsIcon } from 'assets/images/three-dots.svg'
import { ReactComponent as AlertIcon } from 'assets/images/icons/monitor/alert_icon.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/monitor/edit_box_icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/monitor/delete_icon.svg'
import { ReactComponent as ReportIcon } from 'assets/images/icons/monitor/report_icon.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-16.svg'
import { ReactComponent as FilledNotificationIcon } from 'assets/images/icons/monitor/filled_notification_icon.svg'
import millify from 'millify'
import ShareReportModal from 'components/Investigate/Reports/ShareReportModal/ShareReportModal'
import { ShareReportEndpoint } from 'store/report/valueTypes'
import SuccessModal from 'components/Flags/SuccessModal/SuccessModal'
import AlertModal from 'components/AlertModal/AlertModal'
import DeleteConfirmationModal from 'components/Flags/DeleteConfirmationModal/DeleteConfirmationModal'
import { ListSelectedRowData } from 'models/models'

const customGraphColors = {
  shadeColorUp: '#eeeff4',
  lineColorUp: '#A84BD3',
  shadeColorDown: '#eeeff4',
  lineColorDown: '#A84BD3',
  theme: 'purple',
}

const VectorsDashboard = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showSidebar, setShowSidebar] = useState(false)
  const [isOpenExecutiveSummaryModal, setIsOpenExecutiveSummaryModal] = useState(false)
  const [isOpenSnippetsDiscovery, setIsOpenSnippetsDiscovery] = useState(false)
  const [flagToView, setFlagToView] = useState<FlagListObjType | undefined>()
  const [assetToView, setAssetToView] = useState<AssetListObjType | undefined>()
  const [selectedPowerInsightTab, setSelectedPowerInsightTab] = useState<string>('')
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false)
  const [openAlertSuccessModal, setOpenAlertSuccessModal] = useState<boolean>(false)
  const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const { tenantsStore, loaderStore, vectorsStore, assetsStore } = store
  const {
    fetchFolderList,
    narrativeLoading,
    snippetsFilter,
    snippetsTotal,
    resetSnippetFilters,
    setSnippetsFilter,
    fetchPowerInsights,
    fetchSnippets,
    deleteItem,
    fetchPowerInsightsForDashboardPage,
  } = vectorsStore
  const { customThemeLoading, isCorporateCommunicationsTheme } = tenantsStore
  const { fetchCategories } = assetsStore
  const { statsLineChartLoading, isLoadingFeed } = loaderStore

  const [selectedSearchItem, setSelectedSearchItem] = useState<NarrativeListDataType>()

  const isPowerInsightsMode = location.pathname?.includes('/searches/power-insights')

  const detailObject = {
    name: selectedSearchItem?.name,
    conditions: selectedSearchItem?.conditions,
    mode: 'narrative',
    id: selectedSearchItem?.id,
    activeTab: selectedPowerInsightTab,
  }

  const shareModalTitle = {
    narrative: 'Share Narrative',
    watchlist: 'Share Watchlist',
  }

  const buttonHandler = (event: string) => {
    if (event === 'SHARE') {
      setIsShareModalOpen(true)
    }
    // if (event === 'EDIT') {
    //   setOpenEditModal(true)
    // }
    if (event === 'ALERT') {
      setIsAlertModalOpen(true)
    }
    if (event === 'DELETE') {
      setOpenDeleteModal(true)
    }
  }

  const deleteHandler = () => {
    selectedSearchItem?.id &&
      deleteItem({ ...selectedSearchItem, ownerId: '' } as ListSelectedRowData).then((res: any | 'error') => {
        if (res !== 'error') {
          setOpenDeleteModal(false)
        }
      })
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      onClick: () => buttonHandler('SHARE'),
      label: (
        <div className='pi-dropdown-item'>
          <ShareIcon />
          <span>Share</span>
        </div>
      ),
    },
    {
      key: '2',
      onClick: () => buttonHandler('EDIT'),
      label: (
        <div className='pi-dropdown-item'>
          <EditIcon />
          <span>Edit Name</span>
        </div>
      ),
    },
    {
      key: '3',
      onClick: () => buttonHandler('ALERT'),
      label: (
        <div className='pi-dropdown-item'>
          {selectedSearchItem?.alert_id ? <FilledNotificationIcon /> : <AlertIcon />}
          <span>Alerts</span>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div className='pi-dropdown-item'>
          <ReportIcon />
          <span>Export</span>
        </div>
      ),
    },
    {
      key: '5',
      onClick: () => buttonHandler('DELETE'),
      label: (
        <div className='pi-dropdown-item'>
          <DeleteIcon />
          <span>Delete</span>
        </div>
      ),
    },
  ]

  useEffectOnce(() => {
    if (isPowerInsightsMode) return
    fetchFolderList({ params: {} })
    const requestParams = {
      is_asset: true,
    }
    fetchCategories(requestParams)
  })

  useEffect(() => {
    if (!customThemeLoading && !isCorporateCommunicationsTheme) {
      navigate('/')
    }
  }, [isCorporateCommunicationsTheme, customThemeLoading])

  useEffect(() => {
    if (isPowerInsightsMode) {
      fetchSnippets()
      return
    }
    if (selectedSearchItem) {
      fetchPowerInsightsForDashboardPage({
        name: selectedSearchItem.name,
        conditions: selectedSearchItem.conditions,
        mode: 'narrative',
        id: selectedSearchItem.id,
      })
    }
  }, [selectedSearchItem, snippetsFilter])

  useEffect(() => {
    if (isPowerInsightsMode && selectedSearchItem?.id) fetchPowerInsights(detailObject as DetailsObjType)
  }, [snippetsFilter, selectedSearchItem])

  if (customThemeLoading) {
    return (
      <div className='spinner-container'>
        <Spin />
      </div>
    )
  }

  const openExectuiveSummaryModal = () => setIsOpenExecutiveSummaryModal(true)
  const closeExecutiveSummaryModal = () => setIsOpenExecutiveSummaryModal(false)
  const openSnippetsDiscovery = () => setIsOpenSnippetsDiscovery(true)
  const closeSnippetsDiscovery = () => {
    setIsOpenSnippetsDiscovery(false)
    setFlagToView(undefined)
    setAssetToView(undefined)
  }

  const onSelectFlag = (flag: FlagListObjType) => {
    setSnippetsFilter({ ...snippetsFilter, flags: { name: flag.name, conditions: flag?.conditions || {} } })
    setFlagToView(flag)
    openSnippetsDiscovery()
  }

  const onSelectBrandDiscussionItem = (asset: AssetListObjType) => {
    setSnippetsFilter({ ...snippetsFilter, assets: { name: asset.name, conditions: asset?.conditions || {} } })
    setAssetToView(asset)
    openSnippetsDiscovery()
  }

  return (
    <div className='search-dashboard'>
      <Drawer
        width={500}
        forceRender
        placement={'left'}
        open={showSidebar}
        className='search-list-drawer'
        onClose={() => setShowSidebar(false)}>
        <SearchList
          selectedSearchItem={selectedSearchItem}
          setSelectedSearchItem={setSelectedSearchItem}
          showSidebar={true}
        />
      </Drawer>

      <div className='search-dashboard-stats'>
        <Spin spinning={narrativeLoading && !selectedSearchItem}>
          <div className={classNames('filters-section', { powerinsight_mood: isPowerInsightsMode })}>
            <div onClick={() => setShowSidebar(!showSidebar)} className='brand-selected'>
              <h6 className='title'>{selectedSearchItem?.name}</h6>
            </div>
            <div className='executive-summary-btn' onClick={openExectuiveSummaryModal}>
              <ExecutiveSummaryIcon />
              Executive summary
              <ExpandIcon />
            </div>
            {isPowerInsightsMode && (
              <div className='power-insights-options'>
                <span>Power Insights</span>
                <Dropdown menu={{ items }} placement='bottomLeft'>
                  <ThreeDotsIcon />
                </Dropdown>
              </div>
            )}
            <div className='date-filter'>
              <DateFilter days={[1, 7, 30, 90]} pageType={'list'} subStore={'vectors'} addCustomDateOption={false} />
              {!isPowerInsightsMode && (
                <ButtonImage onClick={openSnippetsDiscovery} className='fhc_sc_button'>
                  Show all snippets
                </ButtonImage>
              )}
              {!isPowerInsightsMode && (
                <ButtonImage
                  onClick={() => {
                    setSnippetsFilter({
                      ...snippetsFilter,
                      flags: { name: selectedSearchItem?.name || '', conditions: selectedSearchItem?.conditions || {} },
                    })
                    navigate('power-insights')
                  }}
                  className='fhc_pi_button'
                  icon={PowerInsightsIcon}>
                  Power Insights
                </ButtonImage>
              )}
              {isPowerInsightsMode && (
                <ButtonImage
                  onClick={() => {
                    resetSnippetFilters()
                    navigate('/searches')
                  }}
                  className='fhc_sc_button bt_dashboard'
                  icon={LeftArrowIcon}>
                  Back to dashboard
                </ButtonImage>
              )}
            </div>
            {isOpenExecutiveSummaryModal && (
              <DailyExecutiveSummary
                onClose={closeExecutiveSummaryModal}
                showSubscriptionOption={false}
                subStore='vectors'
              />
            )}
            {isOpenSnippetsDiscovery && (
              <SnippetsDiscovery
                onClose={closeSnippetsDiscovery}
                selectedItem={selectedSearchItem}
                flagToView={flagToView || assetToView}
                subStore='vectors'
              />
            )}
          </div>
          {selectedSearchItem && !isPowerInsightsMode && (
            <div>
              <Filters />
              <Row gutter={24} justify={'start'} align={'top'} className='dashboard-widgets'>
                <Col span={18}>
                  <Row gutter={[0, 24]}>
                    <Col span={24}>
                      <Row className='dashboard-widgets__card__sub-row'>
                        <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                          <MentionViewCard
                            selectedItem={selectedSearchItem}
                            type='Mentions'
                            key='Mentions'
                            customGraphColors={customGraphColors}
                            chartWrapperClassName='cards-chart-wrapper'
                            subStore='vectors'
                          />
                        </Col>
                        <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                          <MentionViewCard
                            selectedItem={selectedSearchItem}
                            type='Views'
                            key='Views'
                            measure='impression'
                            customGraphColors={customGraphColors}
                            chartWrapperClassName='cards-chart-wrapper'
                            subStore='vectors'
                          />
                        </Col>
                        <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                          <MentionViewCard
                            selectedItem={selectedSearchItem}
                            type='Engagement'
                            key='Engagement'
                            measure='engagement'
                            customGraphColors={customGraphColors}
                            chartWrapperClassName='cards-chart-wrapper'
                            subStore='vectors'
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <SuggestedIssues selectedItem={selectedSearchItem} />
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <BrandDiscussions
                        selectedItem={selectedSearchItem}
                        onSelectItem={onSelectBrandDiscussionItem}
                        subStore='vectors'
                      />
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <FollowedIssues
                        selectedBrand={selectedSearchItem}
                        onSelectFlag={onSelectFlag}
                        subStore='vectors'
                      />
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <InfluencerTable subStore='vectors' />
                    </Col>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row gutter={[0, 24]}>
                    <Col span={24} className='dashboard-widgets__card'>
                      <PortfolioShareChart additionalConditions={selectedSearchItem.conditions} />
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <BrandSentiment subStore='vectors' />
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <div id='trendChart'>
                        <div className='chart-heading'>
                          <span className='monitor-assets__graph__title'>Current Trend</span>
                          <DownloadOption elementToCapture='#trendChart' />
                        </div>
                        <MonitorGraph
                          subStore='vectors'
                          dataLoading={statsLineChartLoading}
                          resizeChartTrigger={showSidebar}
                          selectedItem={selectedSearchItem}
                        />
                      </div>
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <PlatformDistributionCard isVerticalDisplay subStore='vectors' />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}

          {isPowerInsightsMode && (
            <Row
              gutter={24}
              justify={'start'}
              align={'top'}
              className={classNames('dashboard-widgets', { powerinsight_dashboard: isPowerInsightsMode })}>
              <Col style={{ width: '226', minWidth: '221px' }}>
                <PowerInsightTabsListVertical setSelectedPowerInsightTab={setSelectedPowerInsightTab} />
              </Col>
              <Col style={{ flex: 1 }}>
                <Row gutter={[24, 24]}>
                  <Col span={15}>
                    <Row>
                      <Col span={24} className='power-insights-components'>
                        <Outlet
                          context={{
                            selectedBrand: selectedSearchItem,
                            subStore: 'vectors',
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={9} className='snippet-section-column'>
                    <Spin spinning={isLoadingFeed} wrapperClassName='snippet-section-loader'>
                      <Row>
                        <Col span={24} className='dashboard-snippet-section'>
                          <div className='dashboard-snippet-section__container'>
                            <div className='dashboard-snippet-section__container__top-container'>
                              <div className='dashboard-snippet-section__container__header'>
                                <p className='dashboard-snippet-section__container__header__title'>Snippets Feed</p>
                                {!!snippetsTotal && (
                                  <p className='dashboard-snippet-section__container__header__count'>
                                    {millify(snippetsTotal || 0)} snippets
                                  </p>
                                )}
                              </div>
                              <SnippetFilter
                                mode={'narrative'}
                                activeTab={''}
                                page={'vectors'}
                                pageType={'details'}
                                subStore={'vectors'}
                                showDashboardActions
                              />
                              <FilterChips
                                subStore={'vectors'}
                                detailObj={detailObject as DetailsObjType}
                                activePowerInsightTab={selectedPowerInsightTab}
                              />
                            </div>
                            <SnippetList
                              mode={'narrative'}
                              isDashboardSnippet
                              showSearchChip={(snippetsFilter?.filter_keyword || '').length > 0}
                              searchedDataResults={snippetsTotal}
                              subStore={'vectors'}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Spin>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Spin>
      </div>

      {isShareModalOpen && selectedSearchItem?.id && (
        <ShareReportModal
          endpoint={selectedSearchItem?.mode as ShareReportEndpoint}
          reportId={selectedSearchItem?.id}
          title={shareModalTitle[selectedSearchItem?.mode as 'narrative' | 'watchlist']}
          desc='Invite team members'
          secondButtonText='Share'
          icon={null}
          onClose={() => setIsShareModalOpen(false)}
        />
      )}
      {openAlertSuccessModal && (
        <SuccessModal
          title='Alert Created Successfully'
          desc='You will start receive new alerts according to the selected preferences'
          onClose={() => setOpenAlertSuccessModal(false)}
          closeButtonText='Continue'
        />
      )}
      <AlertModal
        entity_id={selectedSearchItem?.id as string}
        entity_type={
          selectedSearchItem?.mode === 'community' ? null : (selectedSearchItem?.mode?.toUpperCase() as EntityTypes)
        }
        onClose={() => setIsAlertModalOpen(false)}
        open={isAlertModalOpen}
        alertId={selectedSearchItem?.alert_id as string}
        onSuccess={async () => {
          setOpenAlertSuccessModal(true)
        }}
        onDeleteSuccess={() => {}}
      />

      {openDeleteModal && selectedSearchItem?.id && (
        <DeleteConfirmationModal
          title={`Are you sure you want to delete selected item`}
          onClose={() => setOpenDeleteModal(false)}
          onContinue={deleteHandler}
        />
      )}
    </div>
  )
}

export default observer(VectorsDashboard)
