import { store } from 'store'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Button, Col, Divider, Modal, Select, Tabs, TabsProps, Tag } from 'antd'

import { ReactComponent as DropdownIcon } from 'assets/images/dropdown-icon.svg'

import PulseDDListOptions from './PulseDDListOptions'
import DateTimeRangeFilter from './DateTimeRangeFilter'
import { Unknown } from 'utils/commonTypes'
import { convertDateAndTimeToUTC, convertDateToLocal, convertTimeToLocal, getTime } from 'utils/helper'
import { formatConditions } from './utils'

export type CategoriesTypes = 'searches' | 'brand' | 'flags' | 'influencers'
export type OptionTypes = { value: string; name: string; category: CategoriesTypes }

const defaultSelectedContentItems = {
  brand: [] as Unknown[],
  flags: [] as Unknown[],
  searches: [] as Unknown[],
  influencers: [] as Unknown[],
}

const PulseLeftComponent = observer(() => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false)
  const [isTabsModalOpen1, setIsTabsModalOpen1] = useState<boolean>(false)
  const [isTabsModalOpen2, setIsTabsModalOpen2] = useState<boolean>(false)
  const [isOpenContentModal, setIsOpenContentModal] = useState<boolean>(false)
  const [selectedContentItems1, setSelectedContentItems1] = useState(defaultSelectedContentItems)
  const [selectedContentItems2, setSelectedContentItems2] = useState(defaultSelectedContentItems)
  const [modalSelectedContentItems1, setModalSelectedContentItems1] = useState(defaultSelectedContentItems)
  const [modalSelectedContentItems2, setModalSelectedContentItems2] = useState(defaultSelectedContentItems)
  const [updatedDateAndTime, setUpdatedDateAndTime] = useState({ startDateAndTime: '', endDateAndTime: '' })
  const [selectedTab, setSelectedTab] = useState<'searches' | 'brand' | 'flags' | 'influencers'>('searches')

  const { assetsStore, vectorsStore, flagsStore, mainStore } = store
  const {
    flagsList,
    flagsLoading,
    fetchFlagsListWithConditions,
    paginationValues: flagsPagination,
    setPaginationValues: setFlagsPagination,
  } = flagsStore
  const {
    fetchNarratives,
    fetchWatchlist,
    narrativesListData,
    watchlistListData,
    narrativeLoading,
    paginationValues: searchPagination,
    // snippetsFilter: vectorsSnippetFilters,
    // setSnippetsFilter: setVectorsSnippetFilters,
    setPaginationValues: setSearchPagination,
  } = vectorsStore
  const {
    assetsList,
    assetsLoading,
    fetchAssetsList,
    fetchCategories,
    assetsCategories,
    paginationValues: brandPagination,
    setPaginationValues: setBrandPagination,
  } = assetsStore

  const { fetchSnippetsInfo, snippetsFilter, snippetsWithSurprises, setSnippetsFilter } = mainStore

  const onChange = (key: string) => {
    setSearchPagination({ ...searchPagination, page: 1 })
    setFlagsPagination({ ...flagsPagination, page: 1 })
    setBrandPagination({ ...brandPagination, page: 1 })
    setSelectedTab(key as CategoriesTypes)
  }

  const firstCallRequestParams = {
    page: 1,
    per_page: 10,
    sort: 'name:asc',
  }

  const brandCategory = assetsCategories?.find((item) => item.name === 'Brands')

  const onScroll = async () => {
    if (selectedTab === 'brand' && assetsList.total_count > assetsList.items?.length) {
      await fetchAssetsList({
        params: {
          ...firstCallRequestParams,
          page: brandPagination.page + 1,
          is_asset: true,
          q: `category_id:eq:${brandCategory?.category_id}`,
        },
        isInfiniteScrolling: true,
      })
      setBrandPagination({ ...brandPagination, page: brandPagination.page + 1 })
    }

    if (selectedTab === 'flags' && flagsList.total_count > flagsList.items?.length) {
      await fetchFlagsListWithConditions({
        params: { ...firstCallRequestParams, page: flagsPagination.page + 1 },
        isInfiniteScrolling: true,
      })
      setFlagsPagination({ ...flagsPagination, page: flagsPagination.page + 1 })
    }

    if (selectedTab === 'influencers' && watchlistListData.total_count > watchlistListData.items?.length) {
      await fetchWatchlist({
        params: { ...firstCallRequestParams, page: searchPagination.page + 1 },
        isInfiniteScrolling: true,
      })
      setSearchPagination({ ...searchPagination, page: searchPagination.page + 1 })
    }

    if (selectedTab === 'searches' && narrativesListData.total_count > narrativesListData.items?.length) {
      await fetchNarratives({
        params: { ...firstCallRequestParams, page: searchPagination.page + 1 },
        isInfiniteScrolling: true,
      })
      setSearchPagination({ ...searchPagination, page: searchPagination.page + 1 })
    }
  }

  const modifySelectionState = (existingData: OptionTypes[], newData: OptionTypes) => {
    const isAlreadyExist = existingData?.some((item) => item.value === newData.value)
    if (isAlreadyExist) return existingData?.filter((item) => item.value !== newData.value)
    return [...existingData, newData]
  }

  const handleModalSelectedContentItems = (values: OptionTypes) => {
    if (isTabsModalOpen1)
      setModalSelectedContentItems1({
        ...modalSelectedContentItems1,
        [values.category]: modifySelectionState(modalSelectedContentItems1[values.category], values),
      })
    else
      setModalSelectedContentItems2({
        ...modalSelectedContentItems2,
        [values.category]: modifySelectionState(modalSelectedContentItems2[values.category], values),
      })
  }

  const contentSelection1Conditions = Object.values(modalSelectedContentItems1)?.flatMap((item) =>
    item?.map((item) => item.conditions),
  )

  const contentSelection2Conditions = Object.values(modalSelectedContentItems2)?.flatMap((item) =>
    item?.map((item) => item.conditions),
  )

  const handleContentFilters = () => {
    setSelectedContentItems1(modalSelectedContentItems1)
    setSelectedContentItems2(modalSelectedContentItems2)
    setIsOpenContentModal(false)
    setSnippetsFilter({
      ...snippetsFilter,
      date_key: 'post_uploaded',
    })

    const conditions = formatConditions(contentSelection1Conditions, contentSelection2Conditions)
    // @ts-ignore
    fetchSnippetsInfo({ conditions })
  }

  const handleApplyDate = () => {
    setSnippetsFilter({
      ...snippetsFilter,
      days: {
        ...snippetsFilter.days,
        custom: {
          isApplied: true,
          dateRange: [
            convertDateAndTimeToUTC(updatedDateAndTime.startDateAndTime),
            convertDateAndTimeToUTC(updatedDateAndTime.endDateAndTime),
          ],
        },
      },
      date_key: 'post_uploaded',
    })
    // setVectorsSnippetFilters({
    //   ...vectorsSnippetFilters,
    //   ...snippetsFilter,
    //   days: {
    //     ...snippetsFilter.days,
    //     custom: {
    //       isApplied: true,
    //       dateRange: [
    //         convertDateAndTimeToUTC(updatedDateAndTime.startDateAndTime),
    //         convertDateAndTimeToUTC(updatedDateAndTime.endDateAndTime),
    //       ],
    //     },
    //   },
    // })
    const conditions = formatConditions(contentSelection1Conditions, contentSelection2Conditions)
    // @ts-ignore
    fetchSnippetsInfo({ conditions })
    setIsCalendarOpen(false)
  }

  const clearDateFilter = () => {
    setSnippetsFilter({
      ...snippetsFilter,
      days: {
        ...snippetsFilter.days,
        custom: {
          isApplied: false,
          dateRange: [],
        },
      },
      date_key: 'post_uploaded',
    })
    // setVectorsSnippetFilters({
    //   ...vectorsSnippetFilters,
    //   ...snippetsFilter,
    //   days: {
    //     ...snippetsFilter.days,
    //     custom: {
    //       isApplied: true,
    //       dateRange: [
    //         convertDateAndTimeToUTC(updatedDateAndTime.startDateAndTime),
    //         convertDateAndTimeToUTC(updatedDateAndTime.endDateAndTime),
    //       ],
    //     },
    //   },
    // })
    const conditions = formatConditions(contentSelection1Conditions, contentSelection2Conditions)
    // @ts-ignore
    fetchSnippetsInfo({ conditions })
    setIsCalendarOpen(false)
  }

  const removeTag = (category: CategoriesTypes, isContentGroup1: boolean) => {
    const updatedModalSelectedContentItems1 = isContentGroup1
      ? { ...modalSelectedContentItems1, [category]: [] }
      : modalSelectedContentItems1
    const updatedModalSelectedContentItems2 = !isContentGroup1
      ? { ...modalSelectedContentItems2, [category]: [] }
      : modalSelectedContentItems2

    setModalSelectedContentItems1(updatedModalSelectedContentItems1)
    setModalSelectedContentItems2(updatedModalSelectedContentItems2)

    setSelectedContentItems1(updatedModalSelectedContentItems1)
    setSelectedContentItems2(updatedModalSelectedContentItems2)

    const contentSelection1Conditions = Object.values(updatedModalSelectedContentItems1).flatMap((item) =>
      item?.map((item) => item.conditions),
    )
    const contentSelection2Conditions = Object.values(updatedModalSelectedContentItems2).flatMap((item) =>
      item?.map((item) => item.conditions),
    )

    const conditions = formatConditions(contentSelection1Conditions, contentSelection2Conditions)
    // @ts-ignore
    fetchSnippetsInfo({ conditions })
  }

  const assetsOptions = assetsList?.items?.map((asset) => ({ label: asset.name, value: asset.id, ...asset }))
  const narrativeOptions = narrativesListData?.items?.map((narrative) => ({
    label: narrative.name,
    value: narrative.id,
    ...narrative,
  }))
  const watchlistOptions = watchlistListData?.items?.map((watchlist) => ({
    label: watchlist.name,
    value: watchlist.id,
    ...watchlist,
  }))
  const flagsOptions = flagsList?.items?.map((flag) => ({
    label: flag.name,
    value: flag.id,
    ...flag,
  }))

  const isContentSelected2 = Object.values(selectedContentItems2)?.some((item) => item?.length)
  const isContentSelected = Object.values(selectedContentItems1)?.some((item) => item?.length) || isContentSelected2
  const modalSelectedContentItems = isTabsModalOpen1 ? modalSelectedContentItems1 : modalSelectedContentItems2
  const isDateFilterApplied = snippetsFilter.days.custom.isApplied

  const items: TabsProps['items'] = [
    {
      key: 'searches',
      label: (
        <div className='custom-tab-label'>
          <span className='custom-tab-label__label'>Searches</span>
          {!isEmpty(modalSelectedContentItems.searches) && (
            <span className='custom-tab-label__counter'>{modalSelectedContentItems.searches?.length}</span>
          )}
        </div>
      ),
      children: (
        <PulseDDListOptions
          category='searches'
          onScroll={onScroll}
          loading={narrativeLoading}
          options={narrativeOptions || []}
          modalSelectedContentItems={modalSelectedContentItems}
          handleModalSelectedContentItems={handleModalSelectedContentItems}
        />
      ),
    },
    {
      key: 'brand',
      label: (
        <div className='custom-tab-label'>
          <span className='custom-tab-label__label'>Brands</span>
          {!isEmpty(modalSelectedContentItems.brand) && (
            <span className='custom-tab-label__counter'>{modalSelectedContentItems.brand?.length}</span>
          )}
        </div>
      ),
      children: (
        <PulseDDListOptions
          category='brand'
          onScroll={onScroll}
          loading={assetsLoading}
          options={assetsOptions || []}
          modalSelectedContentItems={modalSelectedContentItems}
          handleModalSelectedContentItems={handleModalSelectedContentItems}
        />
      ),
    },
    {
      key: 'influencers',
      label: (
        <div className='custom-tab-label'>
          <span className='custom-tab-label__label'>Influencers</span>
          {!isEmpty(modalSelectedContentItems.influencers) && (
            <span className='custom-tab-label__counter'>{modalSelectedContentItems.influencers?.length}</span>
          )}
        </div>
      ),
      children: (
        <PulseDDListOptions
          onScroll={onScroll}
          loading={narrativeLoading}
          options={watchlistOptions}
          category='influencers'
          modalSelectedContentItems={modalSelectedContentItems}
          handleModalSelectedContentItems={handleModalSelectedContentItems}
        />
      ),
    },
    {
      key: 'flags',
      label: (
        <div className='custom-tab-label'>
          <span className='custom-tab-label__label'>Flags</span>
          {!isEmpty(modalSelectedContentItems.flags) && (
            <span className='custom-tab-label__counter'>{modalSelectedContentItems.flags?.length}</span>
          )}
        </div>
      ),
      children: (
        <PulseDDListOptions
          onScroll={onScroll}
          loading={flagsLoading}
          options={flagsOptions}
          category='flags'
          modalSelectedContentItems={modalSelectedContentItems}
          handleModalSelectedContentItems={handleModalSelectedContentItems}
        />
      ),
    },
  ]

  useEffect(() => {
    const requestParams = {
      is_asset: true,
    }
    fetchCategories(requestParams)
  }, [])

  useEffect(() => {
    const firstCallRequestParams = {
      page: 1,
      per_page: 10,
      sort: 'name:asc',
    }

    if (selectedTab === 'searches' && isOpenContentModal) {
      fetchNarratives({ params: firstCallRequestParams })
      setSearchPagination({ ...searchPagination, page: 1 })
    }

    if (selectedTab === 'flags' && isOpenContentModal) fetchFlagsListWithConditions({ params: firstCallRequestParams })

    if (selectedTab === 'influencers' && isOpenContentModal) {
      fetchWatchlist({ params: firstCallRequestParams })
      setSearchPagination({ ...searchPagination, page: 1 })
    }

    if (selectedTab === 'brand' && isOpenContentModal)
      fetchAssetsList({
        params: {
          ...firstCallRequestParams,
          is_asset: true,
          q: `category_id:eq:${brandCategory?.category_id}`,
        },
      })
  }, [isOpenContentModal, selectedTab])

  return (
    <Col span={6} className='pulse-dashboard__filters-container'>
      <div className='snippets-filters'>
        <div className='snippets-filters__dropdown-filter-container'>
          <div className='snippets-filters__dropdown-filter-container__title'>Select your content</div>
          {!isContentSelected ? (
            <Select
              showArrow
              size='large'
              mode='tags'
              open={false}
              options={[]}
              placeholder='Select'
              style={{ width: '100%' }}
              suffixIcon={<DropdownIcon />}
              onClick={() => setIsOpenContentModal(!isOpenContentModal)}
              className='snippets-filters__dropdown-filter-container__select'
            />
          ) : (
            <>
              <div className='selected-content-tag-container'>
                {Object.entries(selectedContentItems1)?.map(([key, value]) =>
                  isEmpty(value) ? null : (
                    <Tag
                      className='selected-content-tag'
                      onMouseDown={() => null}
                      closable={true}
                      onClose={() => removeTag(key as CategoriesTypes, true)}
                      style={{ marginInlineEnd: 4, borderStyle: 'dotted', borderRadius: '12px' }}>
                      <span className='category'>{key}</span>
                      {/* @ts-ignore */}
                      <span className='category-values'>{value?.map((item) => item.name).join(', ')}</span>
                    </Tag>
                  ),
                )}
              </div>
              {isContentSelected2 && (
                <>
                  <Divider style={{ border: 'none', color: '#D0D5DD' }}>AND</Divider>
                  <div className='selected-content-tag-container'>
                    {Object.entries(selectedContentItems2)?.map(([key, value]) =>
                      isEmpty(value) ? null : (
                        <Tag
                          className='selected-content-tag'
                          onMouseDown={() => null}
                          closable={true}
                          onClose={() => removeTag(key as CategoriesTypes, false)}
                          style={{ marginInlineEnd: 4, borderStyle: 'dotted', borderRadius: '12px' }}>
                          <span className='category'>{key}</span>
                          {/* @ts-ignore */}
                          <span className='category-values'>{value?.map((item) => item.name).join(', ')}</span>
                        </Tag>
                      ),
                    )}
                  </div>
                </>
              )}
            </>
          )}

          {isContentSelected && (
            <div className='filter-action-buttons'>
              <Button type='primary' onClick={() => setIsOpenContentModal(true)}>
                Edit Selection
              </Button>
              <Button
                type='text'
                onClick={() => {
                  setSelectedContentItems1(defaultSelectedContentItems)
                  setSelectedContentItems2(defaultSelectedContentItems)
                  setSelectedContentItems1(defaultSelectedContentItems)
                  setSelectedContentItems2(defaultSelectedContentItems)
                  setModalSelectedContentItems1(defaultSelectedContentItems)
                  setModalSelectedContentItems2(defaultSelectedContentItems)
                  // @ts-ignore
                  fetchSnippetsInfo({ conditions: [] })
                }}>
                Clear All
              </Button>
            </div>
          )}
        </div>
        <div className='snippets-filters__dropdown-filter-container'>
          <div className='snippets-filters__dropdown-filter-container__title'>Filter by date & time</div>
          {!isDateFilterApplied ? (
            <Select
              showArrow
              size='large'
              mode='tags'
              open={false}
              options={[]}
              placeholder='Select date & time'
              style={{ width: '100%' }}
              suffixIcon={<DropdownIcon />}
              onClick={() => setIsCalendarOpen(!isOpenContentModal)}
              className='snippets-filters__dropdown-filter-container__select'
            />
          ) : (
            <div className='date-selection'>
              <div className='date-selection__container'>
                <div className='date-selection__container__title'>Start date</div>
                <div className='date-selection__container__value'>
                  {/* @ts-ignore */}
                  <div className='date-selection__container__value__date'>
                    {convertDateToLocal(updatedDateAndTime.startDateAndTime)}
                  </div>
                  <div className='date-selection__container__value__time'>
                    at {convertTimeToLocal(updatedDateAndTime.startDateAndTime)}
                  </div>
                </div>
              </div>
              <div className='date-selection__container'>
                <div className='date-selection__container__title'>End date</div>
                <div className='date-selection__container__value'>
                  {/* @ts-ignore */}
                  <div className='date-selection__container__value__date'>
                    {convertDateToLocal(updatedDateAndTime.endDateAndTime)}
                  </div>
                  <div className='date-selection__container__value__time'>
                    at {convertTimeToLocal(updatedDateAndTime.endDateAndTime)}
                  </div>
                </div>
              </div>
              <div className='filter-action-buttons'>
                <Button type='primary' onClick={() => setIsCalendarOpen(true)}>
                  Edit
                </Button>
                <Button type='text' onClick={clearDateFilter}>
                  Clear Selection
                </Button>
              </div>
            </div>
          )}
          <Modal
            open={isCalendarOpen}
            destroyOnClose
            onOk={() => null}
            closable={true}
            onCancel={() => setIsCalendarOpen(false)}
            className='pulse-date-selection-modal'
            footer={
              <div className='pulse-content-selection-modal__footer'>
                <Button type='text' onClick={() => setIsCalendarOpen(false)}>
                  Cancel
                </Button>
                <Button type='primary' onClick={handleApplyDate}>
                  Confirm
                </Button>
              </div>
            }>
            <DateTimeRangeFilter setUpdatedDateAndTime={setUpdatedDateAndTime} subStore='main' />
          </Modal>
        </div>
      </div>
      <div className='snippets-stats'>
        {snippetsWithSurprises[0]?.date && (
          <div className='snippets-stats__container'>
            <div className='snippets-stats__container__title'>Pendulum last update</div>
            <div className='snippets-stats__container__value'>{getTime(snippetsWithSurprises[0]?.date)}</div>
          </div>
        )}
      </div>
      <Modal
        open={isOpenContentModal}
        destroyOnClose
        onOk={() => null}
        closable={true}
        onCancel={() => setIsOpenContentModal(false)}
        className='pulse-content-selection-modal'
        footer={
          <div className='pulse-content-selection-modal__footer'>
            <Button type='primary' onClick={handleContentFilters}>
              Confirm selection
            </Button>
            <Button
              type='text'
              onClick={() => {
                setSelectedContentItems1(defaultSelectedContentItems)
                setModalSelectedContentItems1(defaultSelectedContentItems)
                setSelectedContentItems2(defaultSelectedContentItems)
                setModalSelectedContentItems2(defaultSelectedContentItems)
              }}>
              Clear All
            </Button>
          </div>
        }>
        <div className='snippets-filters__dropdown-filter-container'>
          <div className='snippets-filters__dropdown-filter-container__title'>Select your content</div>
          <div className='snippets-filters__dropdown-filter-container__description'>
            Choose the content sources you want to include in your Pulse feed.
          </div>
          <div
            className='modal-selected-content-container'
            onClick={() => {
              setIsTabsModalOpen1(!isTabsModalOpen1)
              setIsTabsModalOpen2(false)
            }}>
            {Object.entries(modalSelectedContentItems1)?.map(([key, value]) =>
              isEmpty(value) ? null : (
                <Tag
                  className='selected-content-tag'
                  onMouseDown={() => null}
                  closable={true}
                  onClose={() => setModalSelectedContentItems1({ ...modalSelectedContentItems1, [key]: [] })}
                  style={{ marginInlineEnd: 4, borderStyle: 'dotted', borderRadius: '12px' }}>
                  <span className='category'>{key}</span>
                  {/* @ts-ignore */}
                  <span className='category-values'>{value?.map((item) => item.name)?.join(', ')}</span>
                </Tag>
              ),
            )}
            <DropdownIcon className='drop-down-arrow' />
          </div>
          {isTabsModalOpen1 && <Tabs defaultActiveKey='1' items={items} onChange={onChange} key={'tabs-1'} />}
        </div>
        <Divider style={{ border: 'none', color: '#D0D5DD' }}>AND</Divider>

        <div className='snippets-filters__dropdown-filter-container'>
          <div className='snippets-filters__dropdown-filter-container__optional'>
            <div className='option-text'>Optional</div>
            <div className='snippets-filters__dropdown-filter-container__title'>Refine your content </div>
          </div>
          <div className='snippets-filters__dropdown-filter-container__description'>
            Narrow down your content by selecting additional criteria below. This ensures your Pulse feed includes only
            items that match both your selections.
          </div>
          <div
            className='modal-selected-content-container'
            onClick={() => {
              setIsTabsModalOpen2(!isTabsModalOpen2)
              setIsTabsModalOpen1(false)
            }}>
            {Object.entries(modalSelectedContentItems2)?.map(([key, value]) =>
              isEmpty(value) ? null : (
                <Tag
                  className='selected-content-tag'
                  onMouseDown={() => null}
                  closable={true}
                  onClose={() => setModalSelectedContentItems2({ ...modalSelectedContentItems2, [key]: [] })}
                  style={{ marginInlineEnd: 4, borderStyle: 'dotted', borderRadius: '12px' }}>
                  <span className='category'>{key}</span>
                  {/* @ts-ignore */}
                  <span className='category-values'>{value?.map((item) => item.name)?.join(', ')}</span>
                </Tag>
              ),
            )}
            <DropdownIcon className='drop-down-arrow' />
          </div>
          {isTabsModalOpen2 && <Tabs defaultActiveKey='1' items={items} onChange={onChange} key={'tabs-2'} />}
        </div>
      </Modal>
    </Col>
  )
})

export default PulseLeftComponent
